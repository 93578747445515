import "./Scholarships.css";
import React, { useEffect, useState, useRef } from "react";
import {
  Tabs,
  Tab,
  Card,
  Button,
  Row,
  Col,
  Accordion,
  Modal,
} from "react-bootstrap";
import Header from "../../components/Header/Header";
import {
  getScholarships,
  getSponsors,
  getCurrentScholarshipsCycle,
  getScholarshipDetails,
} from "../../utils/BackendService";
import scholarshipDetails from "./scholarships-data.json";
import { Link, useLocation } from "react-router-dom";
import sponsorImagesAddresses from "../ScholarshipDetail/sponsor-images-address.json";
import { findClosestMatch, fetchImagePaths } from "../../utils/StringUtils";
import HeaderHome from "../../components/HeaderHome/HeaderHome";

const Scholarships = () => {
  const [key, setKey] = useState("HighSchool");

  const [currentCycleData, setCurrentCycleData] = useState({});

  const [scholarshipData, setScholarshipData] = useState([]);

  const [selectedScholarship, setSelectedScholarship] = useState({
    id: 52,
    sponsor: {
      id: 1,
      full_name: "Dr. Ike Ahmed",
      bio: "",
      photo: "sponsor_picture/Alsafa-Foods-Canada-Ltd.jpeg",
      city: null,
      cell_number: null,
      industry: null,
      job_title: null,
      company: null,
      non_public_profile: true,
    },
    subcategory: {},
    name: "Ike Ahmed University Entrance Scholarship",
    description:
      '"Dr. Ike Ahmed inspired a whole generation of Muslim high school and university students through the example he set for others to follow. He excelled at academics, gained admission to Medical School at the highly competitive University of Toronto, and concurrently led Muslim organizations on campus. His on-campus leadership included organizing some of the first Canadian Muslim basketball tournaments in Toronto while being immersed in his studies and clinical rotations.\r\n\r\nDr. Ahmed is now an Ophthalmologist and is recognized as one of the world’s most experienced complex eye surgeons. He is known not only for being at the leading edge of innovative treatments but for developing new techniques and surgical devices. Other eye surgeons now reach for Ahmed Diamond Knives and the Ahmed Segment. He is also on the editorial board of six ophthalmology journals and has presented at over 550 medical conferences in Canada and around the world."',
    award_amount: 10000,
    number_of_awards: 1,
    image: "scholarship_images/Ike_Ahmed_University_Entrance_Scholarship.jpg",
  });

  const [show, setShow] = useState(false);

  const postSecondaryTab = useRef();
  const highSchoolTab = useRef();

  const formatDollar = (num) => {
    return new Intl.NumberFormat("en-CA", {
      style: "currency",
      currency: "CAD",
      maximumSignificantDigits: 3,
    }).format(num);
  };

  const handleClose = () => setShow(false);
  const handleShow = async (id) => {
    // This function fetches the details of an individual scholarship based on the name
    const scholarShip = await getScholarshipDetails(id);
    setSelectedScholarship(scholarShip);
    setShow(true);
  };

  useEffect(() => {
    getCurrentCycleData();
    getScholarships().then((data) => setScholarshipData(data));
  }, []);

  const getSubCategories = () => {
    return [...new Set(scholarshipData.map((x) => x.subcategory_name))];
  };

  const setKeyOption = (tabKey) => {
    setKey(tabKey);
    if (tabKey == "Undergraduate") {
      postSecondaryTab.current.classList.add("key");
      highSchoolTab.current.classList.remove("key");
    } else {
      postSecondaryTab.current.classList.remove("key");
      highSchoolTab.current.classList.add("key");
    }
  };

  const removeDuplicates = (array) => {
    return array.reduce((accumulator, current) => {
      if (!accumulator.some((obj) => obj.name == current.name)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
  };

  function formatDate(dateString) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  const getPSDataCount = (subCategory) => {
    return getPostSecondaryData().filter(
      (s) => s.subcategory_name === subCategory
    ).length;
  };

  const getHSDataCount = (subCategory) => {
    return getHighSchoolData().filter((s) => s.subcategory_name == subCategory)
      .length;
  };

  const getPostSecondaryData = () => {
    let postData = scholarshipData.filter(
      (s) => s.level_of_education !== "High School"
    );
    return removeDuplicates(postData);
  };
  const getHighSchoolData = () => {
    let HSData = scholarshipData.filter(
      (s) => s.level_of_education == "High School"
    );
    return removeDuplicates(HSData);
  };

  const getNumberOfAwards = (scholarship) => {
    return scholarship.number_of_awards > 1 ? " - " + scholarship.number_of_awards + " Scholarships" : "" 
  }

  const getCurrentCycleData = async () => {
    const currentCycleData = await getCurrentScholarshipsCycle();
    setCurrentCycleData(currentCycleData);
  };

  // Check Hud Hud
  // useEffect(() => {
  //   console.log(selectedScholarship);
  //   console.log(
  //     findClosestMatch(
  //       selectedScholarship.name,
  //       "sponsor",
  //       scholarshipImagePaths
  //     )
  //   );
  // }, [selectedScholarship]);

  const [scholarshipImagePaths, setScholarshipWinnerImagePaths] = useState([]);

  // const callFetchImagePaths = async () => {
  //   const data = await fetchImagePaths("sponsor");
  //   if (data) {
  //     setScholarshipWinnerImagePaths(data);
  //   }
  // };

  // useEffect(() => {
  //   callFetchImagePaths();
  // }, []);

  return (
    <div className="my-component">
      {/* <Header title="" backgroundUrl="Homepage.jpeg" /> */}
      {/* <HeaderHome backgroundUrl="Scholarships-Header.jpeg" /> */}
      <HeaderHome backgroundUrl="Homepage.jpeg" />
      <div className="main-div">
        <div className="scholarship-info text-center my-4">
          <h1
            style={{
              color: "#0260A8",
              paddingBottom: "1em",
              fontWeight: "bold",
            }}
          >
            {" "}
            Scholarships{" "}
          </h1>
          <p>
            The objective of the MAX Scholarship Fund is to recognize and reward
            High School and Post Secondary students who have excelled in
            academics and combined this achievement with outstanding
            contributions to their school and/or community.
          </p>
          {currentCycleData ? (
            <p>
              Applications for the 2025 Scholarship Cycle will open on February 18, 2025 @ 12:01 AM Eastern Time and will close on March 31, 2025 @ 2:59 AM Eastern Time. The next scholarship cycle will be announced at a later time. Check back frequently and/or subscribe to our newsletter to be reminded of the next scholarship cycle.
            </p>
          ) : null}
        </div>
        <Row className="mb-4 app-row-sch">
          <Col
            className="text-center apply-btns scholar-app-btn-wrapper"
            key="1"
          >
            <Link to="/applicant-consent" target="_blank">
              <Button
                variant="primary app-btn"
                className="mx-2 scholar-app-btn"
                value="Applicant Consent"
              >
                Applicant Consent
              </Button>
            </Link>
            <a href="/public/login" target="_blank">
              <Button
                variant="primary app-btn"
                className="mx-2 scholar-app-btn"
                value="Apply for a Scholarship"
              >
                Apply for a Scholarship
              </Button>
            </a>
          </Col>
        </Row>

        <div className="scholarships-list-container">
          <div className="tabs-container">
            <div
              className="tab highschool-tab key"
              onClick={() => setKeyOption("HighSchool")}
              ref={highSchoolTab}
            >
              High School Scholarships
            </div>
            <div
              className="tab post-secondary-tab"
              onClick={() => setKeyOption("Undergraduate")}
              ref={postSecondaryTab}
            >
              Post Secondary Scholarships
            </div>
          </div>
          {scholarshipData
            ? // ? getSubCategories().map((sub, i) => (
              (key == "Undergraduate"
                ? [
                    ...new Set(
                      getPostSecondaryData().map((x) => x.subcategory_name)
                    ),
                  ]
                : [
                    ...new Set(
                      getHighSchoolData().map((x) => x.subcategory_name)
                    ),
                  ]
              ).map(
                // [...new Set(scholarshipData.map((x) => x.subcategory_name))].map(
                (sub, i) => (
                  <>
                    <Accordion key={i}>
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header>
                          {sub} -{" "}
                          {key == "Undergraduate"
                            ? getPSDataCount(sub)
                            : getHSDataCount(sub)}{" "}
                          Scholarships
                        </Accordion.Header>
                        <Accordion.Body>
                          {key == "Undergraduate"
                            ? getPostSecondaryData()
                                .filter((s) => s.subcategory_name === sub)
                                .map((s, i) => (
                                  <div key={i}>
                                    <div className="scholarship-name-wrapper">
                                      <p
                                        key={i}
                                        onClick={() => handleShow(s.id)}
                                      >
                                        {s.name} -{" "}
                                        {formatDollar(s.award_amount)}
                                        {" "}
                                        {getNumberOfAwards(s)}
                                      </p>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        className="learn-more-btn"
                                        onClick={() => handleShow(s.id)}
                                      >
                                        Learn more
                                      </Button>
                                    </div>
                                  </div>
                                ))
                            : getHighSchoolData()
                                .filter((s) => s.subcategory_name == sub)
                                .map((s, i) => (
                                  <div key={i}>
                                    <div className="scholarship-name-wrapper">
                                      <p
                                        key={i}
                                        onClick={() => handleShow(s.id)}
                                      >
                                        {s.name} -{" "}
                                        {formatDollar(s.award_amount)}
                                      </p>
                                      <Button
                                        variant="primary"
                                        size="sm"
                                        className="learn-more-btn"
                                        onClick={() => handleShow(s.id)}
                                      >
                                        Learn more
                                      </Button>
                                    </div>
                                  </div>
                                ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                )
              )
            : null}

          {selectedScholarship.name ? (
            <Modal
              show={show}
              onHide={handleClose}
              className="winner-modal-box"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <p className="winner-modal-name">
                    {selectedScholarship.name}
                  </p>
                  <p className="winner-modal-scholarship">
                    Award Amount -{" "}
                    {formatDollar(selectedScholarship.award_amount)}
                  </p>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="winner-modal-container">
                  <img
                    id="scholar-modal-img"
                    className="winner-modal-img"
                    // src={findClosestMatch(
                    //   selectedScholarship.name,
                    //   sponsorImagesAddresses
                    // )}
                    // src={findClosestMatch(
                    //   selectedScholarship.name,
                    //   "sponsor",
                    //   scholarshipImagePaths
                    // )}
                    src={"/media/" + selectedScholarship.sponsor.photo}
                  />
                  <p className="winner-modal-bio">
                    {selectedScholarship.description}
                    <br></br><br></br>
                    <p>Eligibility Criteria: </p>
                    <ol>
                      {selectedScholarship.subcategory?.eligibilitycriteria?.map(
                        (criteria, index) => (
                          <li key={index}>{criteria}</li>
                      ))}
                    </ol>
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Link
                  to={`/scholarships/${selectedScholarship.id}`}
                  target="_blank"
                >
                  <Button
                    variant="primary"
                    onClick={handleClose}
                    className="scholarships-open-btn"
                  >
                    Open in New Window
                  </Button>
                </Link>
                <Button variant="primary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Scholarships;
